import { defineStore } from 'pinia'

export const useThemeStore = defineStore('theme', {
  state: () => ({
      client: null,
      pageBackground: {},
      pageTitle: null,
      pageSubTitle: null,
      logo: {},
      boxedLogo: {},
      clientName: null,
      partners: [],
      visuals: [],
      lostPassword: {},
      chartsCount: {},
      loginPage: {},
  }),
  getters: {
    uiClient() {
      return this.client
    },
    uiPageBackground() {
      return this.pageBackground
    },
    uiLogo() {
      return this.logo
    },
    uiBoxedLogo() {
      return this.boxedLogo
    },
    uiClientName() {
      return this.clientName
    },
    uiPartners() {
      return this.partners
    },
    uiVisuals() {
      return this.visuals
    },
    uiLostPassword() {
      return this.lostPassword
    },
    uiLoginPage() {
      return this.loginPage
    },
    uiChartsCount() {
      return this.chartsCount
    },
    uiPageTitle() {
      return this.pageTitle
    },
    uiPageSubTitle() {
      return this.pageSubTitle
    }
  },
  actions: {
    reset() {
        this.client = null
        this.pageBackground = {}
        this.pageTitle = null
        this.pageSubTitle = null
        this.logo = {}
        this.boxedLogo = {}
        this.clientName = null
        this.partners = []
        this.visuals = []
        this.chartsCount = 2
        this.lostPassword = {}
        this.loginPage = {}
    },
    setClient(val) {
      this.client = val
    },
    setPageBackground(val) {
      this.pageBackground = val
    },
    setLogo(val) {
      this.logo = val
    },
    setBoxedLogo(val) {
      this.boxedLogo = val
    },
    setClientName(val) {
      this.clientName = val
    },
    setPartners(val) {
      this.partners = val
    },
    setVisuals(val) {
      this.visuals = val
    },
    setLostPassword(val) {
      this.lostPassword = val
    },
    setLoginPage(val) {
      this.loginPage = val
    },
    setChartsCount(val) {
      this.chartsCount = val
    },
    setPageTitle(val) {
      this.pageTitle = val
    },
    setPageSubTitle(val) {
      this.pageSubTitle = val
    }
  },
})
