<template>
  <Layout>
    <div class="home" v-if="['azae-am','azae-avs','ubi','aap','hygie-recrutement'].includes(ThemeStore.client)">
      <div class="home__header pb-10" :style="layoutBackground">
        <div class="container mx-auto">
          <Header/>
          <div class="px-6 md:px-11 mt-5" v-if="currentParcours">
            <div class="home__header__content mx-auto pt-10 pb-2 md:px-28 rounded-2xl">
              <div class="md:grid grid-cols-2 md:divide-x-2">
                <div class="px-4">
                  <PlayerWidget/>
                </div>
                <div class="px-4">
                  <ProgressionWidget/>
                </div>
              </div>
              <div class="home__header__current_parcours text-center mt-5 pt-5">
                <img :src="currentParcours.icon" alt="" class="mx-auto">
                <h2 class="mt-2 md:mt-0">{{ currentParcours.name }}</h2>
              </div>
            </div>
            <div class="home__parcours mt-2">
              <CurrentParcours :item="currentParcours"/>
            </div>
          </div>
        </div>
      </div>
      <div class="clear-both" v-if="statisticsActivated">
        <Statistics/>
      </div>
    </div>
    <div class="home" v-else>
      <div class="home__header" :style="layoutBackground">
        <div class="home__header__white" :class="{
          home__header__elliptic: !UserStore.performances.enabled
        }">
          <div class="container mx-auto">
            <NewHeader/>
          </div>
        </div>
        <StatsFilter v-if="UserStore.performances.enabled"/>
        <CoursesFilter v-else-if="ThemeStore.client !== 'aapp'" @type-updated="filterCourses"/>
        <!-- <div class="container mx-auto mt-6" v-if="filter == 'tech'">
          <ThematicsFilter @filters-updated="setThematicRefs"/>
        </div> -->
        <!-- <div v-if="filter == 'tech'"> -->
        <div>
          <module-group-list :filter="filter" @group-updated="getCoursesGroup" v-if="!selectedGroup"/>
          <module-list v-else :filter="filter" :group="group" :showTitle="showTitle" @back="selectedGroup = false"/>
        </div>
        <!-- <course-list v-else :filter="filter"/> -->

        <div class="container mx-auto" id="competences" v-if="['esse', 'tech'].includes(filter) || forceNewStats">
          <NewStatistics :theme-store="ThemeStore"/>
        </div>
        <div class="container mx-auto mt-6" id="performances" v-if="UserStore.performances.enabled">
          <Performance/>
        </div>
        <new-footer class="mt-10"/>
      </div>
      <div class="home__modal" v-if="displayMessage">
        <div class="home__modal__content md:flex items-center justify-center">
          <div class="visual hidden lg:block lg:rounded-tl-2xl lg:rounded-bl-2xl">
            <img :src="UserStore.message.icon" class="mx-auto" alt=""/>
          </div>
          <div class="content lg:rounded-tr-2xl lg:rounded-br-2xl">
            <h2 v-html="UserStore.message.title"/>
            <p v-html="UserStore.message.text.replace(/\n/g, '<br />')"/>
            <div class="flex justify-end mt-4">
              <button class="btn-close" @click="displayMessage = !displayMessage">Fermer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
// @ is an alias to /src
import Layout from '@/components/Layout.vue'
import Header from '@/components/Partials/Header.vue'
import NewHeader from '@/components/Partials/NewHeader.vue'
import NewFooter from '@/components/Partials/NewFooter.vue'
import CoursesFilter from '@/components/Partials/CoursesFilter.vue'
import StatsFilter from '@/components/Partials/StatsFilter.vue'
import CourseList from '@/components/Courses/Index.vue'
import ModuleGroupList from '@/components/Modules/Groups/Index.vue'
import ModuleList from '@/components/Modules/Index.vue'
import ThematicsFilter from '@/components/Partials/ThematicsFilter.vue'
import PlayerWidget from '@/components/Home/PlayerWidget.vue'
import ProgressionWidget from '@/components/Home/ProgressionWidget.vue'
import CurrentParcours from '@/components/Parcours/Current';
import Statistics from '@/components/Home/Statistics.vue'
import NewStatistics from '@/components/Home/NewStatistics.vue'
import Performance from '@/components/Home/Performance.vue'
import { useThemeStore } from "@/store/ThemeStore";
import { useUserStore } from "@/store/UserStore"

import UserMixin from '@/mixins/userMixin'
import UIMixin from '@/mixins/uiMixin'
import AuthMixin from '@/mixins/authMixin'

export default {
  mixins: [AuthMixin, UserMixin, UIMixin],
  name: 'Home',
  components: {
    Layout,
    Header,
    NewHeader,
    NewFooter,
    CoursesFilter,
    StatsFilter,
    CourseList,
    ModuleGroupList,
    ModuleList,
    PlayerWidget,
    ProgressionWidget,
    CurrentParcours,
    Statistics,
    NewStatistics,
    Performance,
    ThematicsFilter,
  },
  data () {
    const UserStore = useUserStore();
    const ThemeStore = useThemeStore();
    return {
      forceNewStats: false,
      UserStore: UserStore,
      ThemeStore: ThemeStore,
      uniqueParcours: false,
      selectedGroup: false,
      currentParcours: {},
      group: {},
      showTitle: true,
      courses: [],
      coursesCount: 0,
      coursesPerPage: 0,
      currentPage: 0,
      pagesCount: 0,
      forumCategoryId: null,
      listCarousel: true,
      modules: [],
      filter: 'esse',
      thematicRefs: null,
      displayMessage: false,
    }
  },
  computed: {
    statisticsActivated () {
      return process.env.VUE_APP_ACTIVATE_STATISTICS == 1
    },
    coursesList() {
      return this.courses.filter(elt => {
          const expFolder = elt.ref.split('-')

          return expFolder[0] == this.filter
      })
    }
  },
  mounted() {
    switch (this.ThemeStore.client) {
      case 'aap':
      case 'azae-am':
      case 'azae-avs':
      case 'hygie-recrutement':
      case 'ubi':
        this.fetchParcours()
        break
    }

    this.displayMessage = this.UserStore.message
    
  },
  methods: {
    getCoursesGroup(group) {
      this.showTitle = group.showTitle ?? true

      this.group = {
        ref: group.ref,
        name: group.name
      }

      this.selectedGroup = true
    },
    getListMode (carousel) {
      this.listCarousel = carousel
    },
    getCategoryId (id) {
      this.forumCategoryId = id
    },
    getModules (modules) {
      this.modules = modules
    },
    setThematicRefs (thematicRefs) {
      this.thematicRefs = thematicRefs
      this.fetch()
    },
    filterCourses(type) {
      this.filter = type
      this.selectedGroup = false
    },
    fetchParcours(page) {
        const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/Courses'
        const accessToken = this.$cookies.get('access_token')
        this.fetching = true

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: 'Bearer ' + accessToken
        }

        this.$http.get(url, { headers: headers, params: {
        client: this.ThemeStore.client,
        page: page ?? 1
        } }).then(
            res => {
                // detect if we get an array of parcours or a single parcours
                this.currentParcours = res.data[0]
                this.uniqueParcours = true
                
                this.fetching = false
            },
            error => {
                this.fetching = false

                if (error.response.status == 401) {
                    this.refreshToken(() => {
                        this.fetchParcours(page)
                    })
                    
                    return
                }
            }
        )
    },
  }
}
</script>